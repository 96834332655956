import * as React from "react"
import { Link } from "gatsby"

import Hero from "../components/hero"

import sad from "../images/sad.svg"

const titleStyle = {
    fontSize: "4rem",
    marginTop: 0,
}

const pageNotFoundStyle = {
    marginBottom: "2rem",
}
const linkStyle = {
  fontSize: "1.25rem",
}

const NotFoundPage = () => {
    return (
        <Hero>
            <div className="content has-text-centered">
                <img src={sad} alt="Sad 404"/>
                <h1 style={titleStyle}>404</h1>
                <h2 style={pageNotFoundStyle}>Page not found</h2>
                <p style={linkStyle}>
                    <Link to="/">Go home</Link>
                </p>
            </div>
        </Hero>
    )
}

export default NotFoundPage
